import { Step, useSteps, StepLayoutChildProps } from '@consigli/facade';
import { useServiceName } from '@consigli/hooks';
import { PipelineName, ServiceId } from '@consigli/types';
import { FC, useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ServiceIdIndexValue } from '@/routes';

import { InsightUpload } from './insight-upload';
import { PackageForm } from '../components/package-form';
import { WizardLayout } from '../components/wizard-layout';
import { useCreateInsightPackage } from '../hooks/useCreateInsightPackage';
import { useUploadProgress } from '../hooks/useUploadProgress';

interface InsightWizardProps {
  userInput: JSX.Element;
}

export const InsightWizard = ({ userInput }: InsightWizardProps) => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const serviceId = ServiceIdIndexValue[serviceName] as ServiceId;
  const [formData, setFormData] = useState<FieldValues>({
    iterationName: '',
    language: '',
    needClassification: false,
  });
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [targetCounter, setTargetCounter] = useState(0);
  const [currentCounter, setCurrentCounter] = useState(0);
  const { createDocumentPackage, uploadFiles, startAnalysis } = useCreateInsightPackage({
    setCurrentCounter,
    setTargetCounter,
  });

  const { isUploading, progress } = useUploadProgress(currentCounter, targetCounter);
  const onAnalyze = useCallback(async () => {
    // step 1 , create package
    try {
      setIsProcessing(true);
      const createdPackageId = await createDocumentPackage(
        serviceId,
        formData.iterationName,
        formData.language,
        formData.needClassification,
      );
      if (createdPackageId === undefined) {
        return;
      }

      // step 2, upload files from local state of files, , then initiate analysis phase
      await uploadFiles(localFiles, createdPackageId);

      // step 3, start pipeline
      await startAnalysis(createdPackageId, PipelineName.DEFAULT);
      toast.success(t('package-creation.success-analyzed'));
    } catch (error) {
      toast.error(t(`package-creation.failed-analyzed`));
    } finally {
      setIsProcessing(false);
    }
  }, [
    createDocumentPackage,
    formData,
    localFiles,
    startAnalysis,
    t,
    serviceId,
    uploadFiles,
    setIsProcessing,
  ]);
  const { withStep, StepIndicator, StepOutlet, useStepContext } = useSteps(
    (step: (name: string, component: FC<StepLayoutChildProps>) => Step) => {
      return [
        step(t('package-creation.details'), () => (
          <PackageForm context={useStepContext} setFormData={setFormData} formData={formData}>
            {userInput}
          </PackageForm>
        )),
        step(t('package-creation.upload'), () => (
          <InsightUpload
            localFiles={localFiles}
            setLocalFiles={setLocalFiles}
            useStepContext={useStepContext}
            onAnalyze={onAnalyze}
          ></InsightUpload>
        )),
      ];
    },
  );

  const stepsRenderer = withStep(() => {
    return (
      <>
        <StepIndicator />
        <StepOutlet />
      </>
    );
  });

  return (
    <WizardLayout
      stepsRenderer={stepsRenderer}
      isProcessing={isProcessing}
      isUploading={isUploading}
      progress={progress}
    />
  );
};
