import { formatDistanceToNow } from 'date-fns';
import { enUS, nb, nn, vi, ja, sv } from 'date-fns/locale';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const ChatTimeSince: FC<{ date: Date }> = ({ date }) => {
  const { t } = useTranslation();

  const {
    i18n: { language },
  } = useTranslation();
  const locale = useMemo(() => {
    if (language === 'nb') {
      return nb;
    } else if (language === 'nn') {
      return nn;
    } else if (language === 'ja') {
      return ja;
    } else if (language === 'vi') {
      return vi;
    } else if (language === 'sv') {
      return sv;
    } else {
      return enUS;
    }
  }, [language]);

  return (
    <span className="text-sm text-day-neutral-dark leading-none shrink-0">
      {formatDistanceToNow(date, { includeSeconds: false, locale: locale })} {t('prop-chat.ago')}
    </span>
  );
};
