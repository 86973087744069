import { Tooltip } from 'react-tooltip';

interface HelpTooltipProps {
  id: string;
  tooltip: string;
}

export const HelpTooltip = ({ id, tooltip }: HelpTooltipProps) => (
  <Tooltip
    id={id}
    place="top-start"
    opacity={100}
    classNameArrow="hidden"
    className="bg-day-light-5 p-3 h-auto w-52 text-day-neutral-subtle rounded-tl-[24px] rounded-bl-[24px] rounded-tr-[24px] shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)]"
  >
    <div className="text-xs font-semibold text-left py-4">{tooltip}</div>
  </Tooltip>
);
