import { useMemo } from 'react';

export const useUploadProgress = (currentCounter: number, targetCounter: number) => {
  const isUploading = useMemo(
    () => currentCounter !== targetCounter,
    [currentCounter, targetCounter],
  );

  const progress = useMemo(
    () => Math.floor((currentCounter / targetCounter) * 100 || 0),
    [currentCounter, targetCounter],
  );

  return { isUploading, progress };
};
