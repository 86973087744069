import { useMyUser } from '@consigli/hooks';
import { logout } from '@consigli/oauth';
import { ProjectRole } from '@consigli/types';
import i18n from 'i18next';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { fetchMessageTemplates } from '@/locales/message-templates';
import { Route } from '@/routes';
import { DropdownElement } from '@/util/interfaces';
import { authConfig } from '@/util/oauth/config';

import { PageHeaderDropdown } from './page-header-dropdown';

export const UserMenuButton = () => {
  const { t } = useTranslation();
  const { user } = useMyUser();
  const navigate = useNavigate();

  const hasUserManagementAccess: boolean = useMemo(
    () =>
      user.isSuperuser ||
      user.isOrgAdmin ||
      user.projectAccess.some((project) => project.role === ProjectRole.ADMIN),
    [user.isOrgAdmin, user.projectAccess, user.isSuperuser],
  );

  // UseEffect to fetch message templates for translation
  useEffect(() => {
    if (user) {
      const currentLanguage = i18n.language;
      fetchMessageTemplates(currentLanguage, 'translation');
    }
  }, [user]);

  const profileOptions: DropdownElement[] = [
    {
      text: t('navbar.menu.myprofile'),
      onClick: () => navigate('/profile'),
    },
    ...(hasUserManagementAccess
      ? [
          {
            text: t('navbar.menu.user-management'),
            onClick: () => navigate(Route.USER_MANAGEMENT),
          },
        ]
      : []),
    {
      text: t('navbar.menu.terms'),
      onClick: () => window.open(Route.TERMS, '_blank'),
    },
    {
      text: t('navbar.menu.logout'),
      onClick: () => logout(authConfig),
    },
  ];

  return (
    <div className="relative group">
      <div className="py-1.5 px-2 cursor-pointer hover:bg-day-light-3 rounded-full relative">
        <i className="fas fa-user-circle fa-xl text-day-neutral-dark" />
      </div>
      <PageHeaderDropdown
        header={user.firstName + ' ' + user.lastName}
        elements={profileOptions}
        className="right-0 hidden group-hover:block min-w-[200px]"
      />
    </div>
  );
};
