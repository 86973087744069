import { DocumentType } from '@consigli/types';
import { saveBlob, saveConsigliBlob, saveConsigliZip, saveFileZip } from '@consigli/utils';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyGetFileReadUrlQuery } from '../api';
// works for both Blob and File, though a bit messy
/**
 * A hook to download files (both Blob and File)
 *
 * @param folderName
 * @param useNS3451Path
 *
 * @returns an object with a download function and a boolean indicating if the download is in progress.
 */
export const useDownloadFiles = (folderName, useNS3451Path = false) => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);
    const [getReadUrl] = useLazyGetFileReadUrlQuery();
    const abortControllerRef = useRef(null);
    const getNS3451Path = useCallback((file) => {
        if (file.type === DocumentType.BLOB) {
            if (file.ns3451.length === 0)
                return `${t('ns3451.__uncategorized__')}/${file.name}`;
            const categories = file.ns3451[0].split('NS3451_')[1];
            let path = '';
            for (const index of categories.split('').keys()) {
                const subcategory = categories.substring(0, index + 1);
                path += `NS3451_${t('ns3451.NS3451_' + subcategory)}/`;
            }
            path += file.name;
            return path;
        }
        return '';
    }, [t]);
    // Function to get the readUrls for several Files
    const getSaveFileList = useCallback(async (files) => {
        const fetchPromises = files.map(async (file) => {
            const { readUrl } = await getReadUrl({
                projectId: 'projectId' in file ? file.projectId : 0,
                fileId: file.id,
            }).unwrap();
            return { readUrl, name: file.name };
        });
        const readUrls = await Promise.all(fetchPromises);
        return readUrls.map(({ readUrl, name }) => ({
            readUrl,
            originalFilename: name,
        }));
    }, [getReadUrl]);
    const downloadBlobFiles = useCallback(async (files, signal) => {
        if (files.length > 1) {
            await saveConsigliZip(files.map((file) => ({
                name: file.name,
                blobUrl: file.blobUrl ?? '',
            })), folderName, signal);
        }
        else if (!signal.aborted) {
            saveConsigliBlob({ name: files[0].name, blobUrl: files[0].blobUrl ?? '' });
        }
    }, [folderName]);
    const downloadNonBlobFiles = useCallback(async (files, signal) => {
        if (files.length > 1) {
            const saveFiles = await getSaveFileList(files);
            await saveFileZip(saveFiles, folderName, signal);
        }
        else {
            const { readUrl } = await getReadUrl({
                projectId: 'projectId' in files[0] ? files[0].projectId : 0,
                fileId: files[0].id,
            }).unwrap();
            if (!signal.aborted) {
                const response = await fetch(readUrl, { signal });
                const data = await response.blob();
                saveBlob(files[0].name, data);
            }
        }
    }, [getSaveFileList, getReadUrl, folderName]);
    const performDownload = useCallback(async (files) => {
        try {
            toast.info(t('document-list.download-begin', { n: files.length }));
            abortControllerRef.current = new AbortController();
            const { signal } = abortControllerRef.current;
            if (files[0].type === DocumentType.BLOB) {
                await downloadBlobFiles(files, signal);
            }
            else {
                await downloadNonBlobFiles(files, signal);
            }
        }
        catch (error) {
            if (error.name === 'AbortError') {
                toast.info(t('document-list.download-aborted'));
            }
            else {
                console.error(error);
                toast.error(t('document-list.download-failed'));
            }
        }
    }, [t, downloadBlobFiles, downloadNonBlobFiles]);
    const download = useCallback(async (files) => {
        setIsDownloading(true);
        try {
            const processedFiles = files.map((file) => ({
                ...file,
                name: useNS3451Path ? getNS3451Path(file) : file.name,
            }));
            await performDownload(processedFiles);
        }
        catch (error) {
            if (error.name === 'AbortError') {
                toast.info(t('document-list.download-aborted'));
            }
            else {
                console.error(error);
                toast.error(t('document-list.download-failed'));
            }
        }
        finally {
            setIsDownloading(false);
        }
    }, [performDownload, useNS3451Path, getNS3451Path, t]);
    const abortDownload = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);
    return {
        download,
        isDownloading,
        resetState: () => setIsDownloading(false),
        abortDownload,
    };
};
