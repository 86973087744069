import { Language } from '@consigli/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQueryPackagePropChatQuery } from '../api';
const getLanguage = (lang) => {
    switch (lang) {
        case 'en':
            return Language.EN;
        case 'nb':
            return Language.NB;
        case 'nn':
            return Language.NN;
        case 'sv':
            return Language.SV;
        case 'vi':
            return Language.VI;
        case 'ja':
            return Language.JA;
        default:
            return Language.EN;
    }
};
export const useQueryPropChat = () => {
    const { i18n } = useTranslation();
    const [queryPropChat] = useLazyQueryPackagePropChatQuery();
    const queryPackagePropChat = useCallback(async ({ projectId, packageId, question }) => {
        const query = {
            question,
            language: getLanguage(i18n.language),
        };
        return await queryPropChat({ projectId, packageId, query }).unwrap();
    }, [i18n, queryPropChat]);
    return { queryPackagePropChat };
};
