import { DocumentsType } from '@consigli/types';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

interface DocumentsPopupProps {
  files: DocumentsType[];
  setShowPopup: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  onClose: () => void;
}

export const DocumentsPopup = ({ files, setShowPopup, children, onClose }: DocumentsPopupProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between items-center pb-4">
        <div className="font-bold pr-8">
          {t('document-list.selected-documents', { count: files.length })}
        </div>
        <FaTimes
          className="cursor-pointer"
          onClick={() => {
            setShowPopup(false);
            onClose();
          }}
        />
      </div>
      <div className="flex space-x-2">{children}</div>
    </>
  );
};
