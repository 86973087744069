import { TextInput } from '@consigli/facade';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IterationNameProps {
  validateName: (value: string) => boolean | string;
  label: string;
  errorMessage?: string;
}

export const IterationName = ({ validateName, label, errorMessage }: IterationNameProps) => {
  const { register, formState, resetField } = useFormContext<FieldValues>();
  const { errors } = formState;
  const { t } = useTranslation();

  return (
    <span className="mb-4 mt-4">
      <TextInput
        label={label}
        className="my-1 border rounded"
        {...register('iterationName', {
          required: errorMessage,
          validate: validateName,
          maxLength: {
            value: 50,
            message: `${t('package-creation.too-long')}`,
          },
        })}
        onReset={() => resetField('iterationName')}
      />
      {errors.iterationName && (
        <div className="text-red-700 text-sm">{errors.iterationName?.message as string}</div>
      )}
    </span>
  );
};
