import { Language } from '@consigli/types';
import i18n from 'i18next';
/**
 * @returns the currently selected language as a Language enum.
 */
export function useCurrentLanguage() {
    const currentLanguage = i18n.language;
    switch (currentLanguage) {
        case 'en-US':
        case 'en-GB':
        case 'en':
            return Language.EN;
        case 'sv':
            return Language.SV;
        case 'vi':
            return Language.VI;
        case 'ja':
            return Language.JA;
        case 'no-NN':
        case 'nn':
            return Language.NN;
        case 'no-NB':
        default:
            return Language.NB;
    }
}
