import { LoadingArea } from '@consigli/facade';
import { useAcceptInviteMutation, useLazyGetInvitesQuery, useMyUser } from '@consigli/hooks';
import { InviteStatus } from '@consigli/types';
import { useEffect, type FC, type PropsWithChildren } from 'react';

export const InviteCheck: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useMyUser();

  const [getInvites] = useLazyGetInvitesQuery();
  const [acceptInviteMutation] = useAcceptInviteMutation();

  // UseEffect to accept any pending invites where the current user is recipient
  useEffect(() => {
    const handleInvites = async () => {
      if (user && !user.isSuperuser) {
        const { data } = await getInvites({
          page: 'all',
          status: InviteStatus.PENDING,
        });

        const invites = data?.results;

        if (invites) {
          const results = await Promise.allSettled(
            invites
              .filter((invite) => invite.recipient === user.email)
              .map(async (invite) => {
                try {
                  const response = await acceptInviteMutation(invite.id);
                  return { invite, error: response.error };
                } catch (error) {
                  return { invite, error };
                }
              }),
          );

          const hasErrors = results.some(
            (result) => result.status === 'rejected' || result.value.error,
          );
          if (hasErrors) {
            console.error('Some invites failed to be accepted:', results);
          }
        }
      }
    };

    handleInvites();
  }, [acceptInviteMutation, user, getInvites]);

  return user ? (
    <>{children}</>
  ) : (
    <LoadingArea title="Checking authorization" className="mt-[40vh] mx-auto" />
  );
};
