import { useMemo } from 'react';
import { useGetOrganizationQuery } from '../api';
/**
 * Hook to get the organization for a specific id.
 */
export const useOrganization = (id) => {
    const { data: organization, isLoading, isError, error } = useGetOrganizationQuery({ id });
    return useMemo(() => ({
        organization: organization,
        isLoading,
        isError,
        error,
    }), [error, isError, isLoading, organization]);
};
