import { Button } from '@consigli/facade';
import { useServiceName, useProjectId, useServiceId, useServiceCategory } from '@consigli/hooks';
import { ServiceCategory, ServiceName } from '@consigli/types';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowLeft, HiOutlineChatAlt2 } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { useNavigationHistory } from '@/hooks/use-navigation-history';
import { LayoutPackage } from '@/pages/engineering/layout-package';
import { StructuralPackage } from '@/pages/structural/structural-package';
import { Route } from '@/routes';
import { TabConfig } from '@/util/types';

import { WorkspaceTabsItem } from './workspace-tabs-item';
import { DocumentPackage } from '../../../organisms/workspace-tabs/document-package';

const INSIGHT_TABS: TabConfig[] = [
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.DASHBOARD}`,
    name: 'servicetabs.overview',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.FINDINGS}`,
    name: 'servicetabs.riskassessment',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.DOCUMENTS}`,
    name: 'servicetabs.documents',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.CATEGORIZATION}`,
    name: 'servicetabs.folders',
  },
];

const LAYOUT_TABS: TabConfig[] = [
  {
    href: (serviceName, projectId, layoutId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${layoutId}/${Route.INPUT}`,
    name: 'optimization-tabs.input',
  },
  {
    href: (serviceName, projectId, layoutId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${layoutId}/${Route.RESULTS}`,
    name: 'optimization-tabs.results',
  },
];

const STRUCTURAL_TABS: TabConfig[] = [
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.INPUT}`,
    name: 'structural-tabs.input',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.RESULTS}`,
    name: 'structural-tabs.results',
  },
];

interface WorkspaceTabsProps {
  hideTabs?: boolean;
}

export const WorkspaceTabs: FC<WorkspaceTabsProps> = ({ hideTabs }) => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const projectId = useProjectId();
  const serviceId = useServiceId();
  const navigate = useNavigate();
  const serviceCategory = useServiceCategory();
  const { defaultBackButtonClick } = useNavigationHistory();

  const filterTabs = (serviceName: string, tabs: TabConfig[]) => {
    return serviceName === ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE
      ? tabs
      : tabs.filter((obj) => obj.name !== 'servicetabs.folders');
  };

  const tabNames = (category: ServiceCategory) => {
    switch (category) {
      case ServiceCategory.INSIGHT:
        return filterTabs(serviceName, INSIGHT_TABS);
      case ServiceCategory.LAYOUT_OPTIMIZATION:
        return LAYOUT_TABS;
      case ServiceCategory.STRUCTURAL_ENGINEERING:
        return STRUCTURAL_TABS;
    }
  };

  const renderScrollList = (category: ServiceCategory) => {
    switch (category) {
      case ServiceCategory.INSIGHT:
        return <DocumentPackage />;
      case ServiceCategory.LAYOUT_OPTIMIZATION:
        return <LayoutPackage />;
      case ServiceCategory.STRUCTURAL_ENGINEERING:
        return <StructuralPackage />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full">
      {renderScrollList(serviceCategory)}
      <nav className="grid md:grid-cols-9 grid-cols-1 w-full py-2 px-8">
        <div className="flex flex-row lg:col-span-2  md:col-span-3 col-span-1 justify-center md:justify-start xl:my-0">
          <Button
            tertiary
            rounded
            className="text-base my-0 md:m-3 font-semibold"
            icon={HiOutlineArrowLeft}
            onClick={defaultBackButtonClick}
          >
            {t('riskassessment.back')}
          </Button>
        </div>
        {!hideTabs && (
          <>
            <div className="lg:col-span-5 md:col-span-3 col-span-1 lg:my-0 my-2">
              <div className="flex flex-col h-full align-middle justify-center">
                <div className="flex flex-row justify-center border border-day-secondary rounded-lg w-full max-w-max mx-auto h-fit">
                  {tabNames(serviceCategory).map(({ href, name }, index) => (
                    <div
                      key={name}
                      className={`${index !== tabNames(serviceCategory).length - 1 ? 'mr-1' : ''}`}
                    >
                      <WorkspaceTabsItem
                        name={name}
                        href={href(serviceName, projectId, serviceId)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="lg:col-span-2 md:col-span-3 col-span-1 flex align-middle items-center lg:justify-end justify-center">
              {!hideTabs && serviceCategory === ServiceCategory.INSIGHT && (
                <Button
                  primary
                  icon={HiOutlineChatAlt2}
                  iconSize={26}
                  iconColor={'white'}
                  className="rounded-full bg-day-dark-1 h-[42px]"
                  onClick={() =>
                    navigate(
                      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.PROPCHAT}`,
                    )
                  }
                >
                  {t('servicetabs.propchat')}
                </Button>
              )}
            </div>
          </>
        )}
      </nav>
    </div>
  );
};
