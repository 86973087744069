import {
  useGetLayoutOptimizationsQuery,
  useGetPackagesByServiceIdQuery,
  useGetStructuralEngineeringsQuery,
  useProjectId,
  useServiceName,
} from '@consigli/hooks';
import { ServiceId, ServiceName } from '@consigli/types';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ServiceIdIndexValue } from '@/routes';

import { IterationName } from './iteration-name';
import { NeedClassification } from './need-classification';
import { SelectLanguage } from './select-language';
import { WizardNextButton } from './wizard-next-button';
import { useValidateName } from '../hooks/useValidateName';

export const useFetchValidateName = (
  getPackagesQuery:
    | typeof useGetPackagesByServiceIdQuery
    | typeof useGetStructuralEngineeringsQuery
    | typeof useGetLayoutOptimizationsQuery,
  { projectId, serviceId }: { projectId: number; serviceId?: ServiceId },
) => {
  const validateName = useValidateName(getPackagesQuery, {
    projectId,
    serviceId,
  });
  return { validateName };
};

export const InsightForm = () => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const projectId = useProjectId();
  const { formState, getValues } = useFormContext<FieldValues>();
  const { isDirty } = formState;
  const formData = getValues();
  const serviceId = ServiceIdIndexValue[serviceName] as ServiceId;
  const { validateName } = useFetchValidateName(useGetPackagesByServiceIdQuery, {
    projectId,
    serviceId,
  });
  return (
    <>
      <IterationName
        validateName={validateName}
        label={
          serviceName === ServiceName.OFFER
            ? t('package-creation.provider')
            : t('package-creation.analysis-name')
        }
        errorMessage={`${t('createproject.invalid-project-name-empty')}*`}
      />
      <SelectLanguage />
      <WizardNextButton disabled={!isDirty && !formData.iterationName && !formData.language} />
    </>
  );
};
export const FdvForm = () => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const projectId = useProjectId();
  const serviceId = ServiceIdIndexValue[serviceName] as ServiceId;
  const { formState, getValues } = useFormContext<FieldValues>();
  const formData = getValues();
  const { isDirty } = formState;
  const { validateName } = useFetchValidateName(useGetPackagesByServiceIdQuery, {
    projectId,
    serviceId,
  });
  return (
    <>
      <IterationName
        validateName={validateName}
        label={
          serviceName === ServiceName.OFFER
            ? t('package-creation.provider')
            : t('package-creation.analysis-name')
        }
        errorMessage={`${t('createproject.invalid-project-name-empty')}*`}
      />
      <SelectLanguage />
      <NeedClassification />
      <WizardNextButton disabled={!isDirty && !formData.iterationName && !formData.language} />
    </>
  );
};
export const StructuralForm = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const { formState, getValues } = useFormContext<FieldValues>();
  const { isDirty } = formState;
  const formData = getValues();
  const { validateName } = useFetchValidateName(useGetStructuralEngineeringsQuery, {
    projectId,
  });
  return (
    <>
      <IterationName
        validateName={validateName}
        label={t('package-creation.building-name')}
        errorMessage={`${t('package-creation.building-name-required')}*`}
      />
      <WizardNextButton disabled={!isDirty && !formData.iterationName} />
    </>
  );
};
