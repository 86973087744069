import { useTranslation } from 'react-i18next';

export const CustomerSupportButton = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    window.open('mailto:info@consigli.app', '_blank');
  };

  return (
    <div className="py-1.5 px-2 cursor-pointer hover:bg-day-light-3 rounded-full">
      <button
        className="fas fa-circle-question fa-xl text-day-neutral-dark"
        onClick={handleClick}
        title={t('navbar.menu.customerservice')}
      />
    </div>
  );
};
