import { mapToCamelCase, mapToSnakeCase } from '@consigli/utils';
import { z } from 'zod';
import { userSchema } from './user';
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["PUBLIC_REAL_ESTATE_OWNER"] = "PUBLIC_REAL_ESTATE_OWNER";
    OrganizationType["PRIVATE_REAL_ESTATE_OWNER"] = "PRIVATE_REAL_ESTATE_OWNER";
    OrganizationType["CONTRACTOR"] = "CONTRACTOR";
    OrganizationType["SUBCONTRACTOR"] = "SUBCONTRACTOR";
    OrganizationType["CONSULTANT"] = "CONSULTANT";
})(OrganizationType || (OrganizationType = {}));
export const organizationTypeSchema = z.nativeEnum(OrganizationType);
export var OrganizationRole;
(function (OrganizationRole) {
    OrganizationRole["ADMIN"] = "ADMIN";
    OrganizationRole["USER"] = "USER";
})(OrganizationRole || (OrganizationRole = {}));
export const organizationSchema = z
    .object({
    id: z.string().uuid(),
    name: z.string().min(1),
    type: organizationTypeSchema,
    owned_by: userSchema.innerType().shape.id,
    created_at: z.string(),
    domain: z.string().optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const createOrganizationRequestSchema = organizationSchema
    .innerType()
    .pick({
    name: true,
    type: true,
    domain: true,
})
    .strict()
    .transform(mapToSnakeCase);
export const getOrganizationsResponseSchema = z.array(organizationSchema).transform(mapToCamelCase);
