import { UseStepContext } from '@consigli/facade';
import { Dispatch, SetStateAction } from 'react';

import { SupportedFileFormats } from '@/atoms/supported-file-formats';

import { UserUpload } from '../components/user-upload';
interface InsightUploadProps {
  localFiles: File[];
  setLocalFiles: Dispatch<SetStateAction<File[]>>;
  useStepContext: UseStepContext;
  onAnalyze: () => void;
}

export const InsightUpload = ({
  localFiles,
  setLocalFiles,
  useStepContext,
  onAnalyze,
}: InsightUploadProps) => {
  return (
    <UserUpload
      localFiles={localFiles}
      setLocalFiles={setLocalFiles}
      useStepContext={useStepContext}
      onAnalyze={onAnalyze}
      infoMessage={<SupportedFileFormats />}
    ></UserUpload>
  );
};
