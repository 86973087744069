import { Button, MessagePanel, MessagePanelTypes } from '@consigli/facade';
import { useServiceName } from '@consigli/hooks';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircle } from 'react-icons/io';
import Popup from 'reactjs-popup';

import { HelpTooltip } from './help-tooltip';

interface RequiredFilesProps {
  setShowMessagePanel: Dispatch<SetStateAction<boolean>>;
}

export const RequiredStructuralFiles = ({ setShowMessagePanel }: RequiredFilesProps) => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  return (
    <MessagePanel type={MessagePanelTypes.INFO} onClose={() => setShowMessagePanel(false)}>
      <p className="font-bold">{t('package-creation.filetype-info.necessary-files')}</p>
      <p>{t(`package-creation.filetype-info.${serviceName}.first-filetype`)}</p>
    </MessagePanel>
  );
};

interface IFCHelpIconProps {
  setShowPopup: Dispatch<SetStateAction<boolean>>;
}
const IFCHelpIcon = ({ setShowPopup }: IFCHelpIconProps) => {
  const { t } = useTranslation();
  const onClose = () => setShowPopup(false);
  return (
    <Popup
      overlayStyle={{ background: 'rgba(100, 100, 100, 0.35)' }}
      contentStyle={{
        backgroundColor: '#edf2f7',
        borderRadius: '10px',
        width: '90%',
        maxHeight: '750px',
        maxWidth: '800px',
        overflow: 'auto',
      }}
      modal
      open
      onClose={onClose}
    >
      <div className="p-4">
        <div className="font-bold py-4 flex justify-center">{t('help-icon.ifc-file-input')}</div>
        <div className="mb-4">{t('help-icon.ifc-file-input-description')}</div>
        <>
          <h2 className="font-bold py-1">{t('help-icon.space')}</h2>
          <ul className="px-4">
            <li className="list-disc">{t('help-icon.space-description')}</li>
          </ul>
        </>
        <>
          <h2 className="font-bold py-1">{t('help-icon.slabs')}</h2>
          <ul className="px-4">
            <li className="list-disc">{t('help-icon.slabs-description')}</li>
          </ul>
        </>
        <>
          <h2 className="font-bold py-1">{t('help-icon.ceiling-coverings')}</h2>
          <ul className="px-4">
            <li className="list-disc">{t('help-icon.ceiling-coverings-description')}</li>
          </ul>
        </>
        <>
          <h2 className="font-bold py-1">{t('help-icon.walls')}</h2>
          <ul className="px-4">
            <li className="list-disc">{t('help-icon.walls-description')}</li>
          </ul>
        </>
        <>
          <h2 className="font-bold py-1">{t('help-icon.doors')}</h2>
          <ul className="px-4">
            <li className="list-disc">{t('help-icon.doors-description')}</li>
          </ul>
        </>
        <>
          <h2 className="font-bold py-1">{t('help-icon.windows')}</h2>
          <ul className="px-4">
            <li className="list-disc">{t('help-icon.windows-description')}</li>
          </ul>
        </>
        <>
          <h2 className="font-bold py-1">{t('help-icon.stairs')}</h2>
          <ul className="px-4">
            <li className="list-disc">{t('help-icon.stairs-description')}</li>
          </ul>
        </>
        <div className="flex justify-center">
          <Button secondary onClick={onClose}>
            {t('help-icon.close')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

interface SpecificationIconProps {
  setShowSpecificationPopup: Dispatch<SetStateAction<boolean>>;
}

const SpecificationIcon = ({ setShowSpecificationPopup }: SpecificationIconProps) => {
  const { t } = useTranslation();
  const onClose = () => setShowSpecificationPopup(false);
  return (
    <Popup
      overlayStyle={{ background: 'rgba(100, 100, 100, 0.35)' }}
      contentStyle={{
        backgroundColor: '#edf2f7',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '400px',
      }}
      modal
      open
      onClose={onClose}
    >
      <div className="p-4 relative overflow-auto">
        <>{t('help-icon.specification-description')}</>
        <div className="flex justify-center mt-4">
          <Button secondary onClick={onClose}>
            {t('help-icon.close')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export const RequiredOptimizationFiles = ({ setShowMessagePanel }: RequiredFilesProps) => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const [showIfcPopup, setShowIfcPopup] = useState(false);
  const [showSpecificationPopup, setShowSpecificationPopup] = useState(false);
  const secondFiletypeText = t(`package-creation.filetype-info.${serviceName}.second-filetype`);
  const [tooltip] = useState(true);

  return (
    <>
      <MessagePanel onClose={() => setShowMessagePanel(false)}>
        <p className="font-bold">{t('package-creation.filetype-info.necessary-files')}</p>
        <p className="flex items-center">
          <span className="flex mx-1">
            <div className="h-7 cursor-pointer">
              <IoIosInformationCircle
                color="#7588a3"
                size={24}
                onClick={() => setShowIfcPopup(true)}
                data-tooltip-id={'help-tooltip-1'}
              />
              {tooltip && <HelpTooltip id="help-tooltip-1" tooltip={t('help-icon.tooltip-text')} />}
            </div>
            <div className="ml-1">
              {t(`package-creation.filetype-info.${serviceName}.first-filetype`)}
            </div>
          </span>
        </p>
        {secondFiletypeText && (
          <p className="flex items-center">
            <span className="flex mx-1">
              <div className="h-7 cursor-pointer">
                <IoIosInformationCircle
                  color="#7588a3"
                  size={24}
                  onClick={() => setShowSpecificationPopup(true)}
                  data-tooltip-id={'help-tooltip-2'}
                />
                {tooltip && (
                  <HelpTooltip id="help-tooltip-2" tooltip={t('help-icon.tooltip-text')} />
                )}
              </div>
              <div className="ml-1">
                {t(`package-creation.filetype-info.${serviceName}.second-filetype`)}
              </div>
            </span>
          </p>
        )}
      </MessagePanel>
      {showIfcPopup && <IFCHelpIcon setShowPopup={setShowIfcPopup} />}
      {showSpecificationPopup && (
        <SpecificationIcon setShowSpecificationPopup={setShowSpecificationPopup} />
      )}
    </>
  );
};
