import { ActionStatus, createFindingResponseSchema, createExportResponseSchema, createLayoutOptimizationResponseSchema, createStructuralEngineeringResponseSchema, createPackageResponseSchema, createProjectResponseSchema, getBlobReadUrlResponseSchema, getBlobsResponseSchema, blobSchema, getConflictTypesResponseSchema, getFindingsResponseSchema, getFindingCommentsResponseSchema, createFindingCommentResponseSchema, getExportResponseSchema, getLayoutOptimizationsResponseSchema, getUserResponseSchema, getPackagesResponseSchema, PipelineName, getProcessorJobSchema, getProcessorJobsSchema, getProcessorJobStageSchema, getProcessorJobStagesSchema, getProjectPhasesResponseSchema, getProjectsResponseSchema, getProjectTypesResponseSchema, startPipelineResponseSchema, updateFindingResponseSchema, updateExportResponseSchema, getProcessorJobStageTasksSchema, getProcessorJobStageTaskSchema, fileResponseSchema, fileUploadUrlResponseSchema, fileReadUrlResponseSchema, layoutOptimizationResultSchema, structuralEngineeringResultSchema, structuralEngineeringSchema, getStructuralEngineeringResultsResponseSchema, parsedDataResponseSchema, layoutOptimizationSchema, getOrganizationsResponseSchema, organizationSchema, updateBatchBlobsResponseSchema, getUsersResponseSchema, getInvitesResponseSchema, getLayoutOptimizationFilesResponseSchema, getLayoutOptimizationResultsResponseSchema, blobFilterCountSchema, getFindingsActionCountResponseSchema, findingSchema, getFindingsConflictTypeCountResponseSchema, getFindingsUniqueBlobsResponseSchema, excelExportResponseSchema, inviteSchema, getInviteCountResponseSchema, getUserCountResponseSchema, getBlobNSCountResponseSchema, projectSchema, getBlobHistoryResponseSchema, getFindingsCommentersResponseSchema, getBlobUniqueDocumentTypesResponseSchema, documentPackageSchema, GetStructuralEngineeringFilesResponseSchema, getStructuralEngineeringResponseSchema, structuralEngineeringResultsDashboardSchema, blobNameListSchema, } from '@consigli/types';
import { mapToCamelCase, mapToSnakeCase } from '@consigli/utils';
import { createApi, } from '@reduxjs/toolkit/query/react';
import { packageChatStatusResponseSchema, packageChatQueryResponseSchema, } from '../types/chat-api';
import { getDashboardDataResponseSchema, } from '../types/dashboard';
import { sendFindingsMailResponseSchema, } from '../types/send-mail';
const createTransformer = (schema, name) => (data) => {
    const result = schema.safeParse(data);
    if (result.success) {
        return result.data;
    }
    // preferably we want to safeparse it with zod, but if that fails
    // we will just camelCase hand off the result
    console.warn(`Data mismatch in API call ${name}: `, result.error);
    return mapToCamelCase(data);
};
/**
 * This function takes an input object and transforms it into a new object suitable for use as query parameters.
 * It does this by iterating over each key-value pair in the input object and applying the following rules:
 * - If the value is undefined, an empty string, or an empty array, it is ignored.
 * - Otherwise, the value is copied as is.
 *
 * @param input - The input object to transform into query parameters.
 * @returns A new object with the transformed key-value pairs.
 */
function createParams(input) {
    const params = {};
    for (const key in input) {
        if (input[key] !== undefined &&
            input[key] !== '' &&
            !(Array.isArray(input[key]) && input[key].length === 0)) {
            params[key] = input[key];
        }
    }
    return params;
}
export const createApiClient = ({ baseQuery, coreApiBaseUrl, chatApiBaseUrl, }) => {
    return createApi({
        reducerPath: 'consigliApi',
        baseQuery: baseQuery,
        tagTypes: [
            'Projects',
            'DocumentPackages',
            'Blob',
            'Blobs',
            'Findings',
            'FindingComments',
            'FindingCommenters',
            'LayoutOptimization',
            'LayoutOptimizationResults',
            'LayoutOptimizationFiles',
            'ProcessorJobs',
            'Files',
            'Users',
            'ProjectPermissions',
            'Organization',
            'Exports',
            'Invites',
            'NSCount',
            'UniqueDocumentTypes',
            'DashboardData',
            'StructuralEngineering',
            'StructuralEngineeringResults',
            'StructuralEngineeringFiles',
        ],
        endpoints: (builder) => ({
            /* Core API Endpoints */
            getProjects: builder.query({
                query: ({ page, pageSize, search }) => {
                    const params = createParams({ page, pageSize, search });
                    return {
                        url: `${coreApiBaseUrl}/projects/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Projects'],
                transformResponse: createTransformer(getProjectsResponseSchema, 'GetProjects'),
            }),
            getProject: builder.query({
                query: ({ projectId }) => `${coreApiBaseUrl}/projects/${projectId}/`,
                providesTags: ['Projects'],
                transformResponse: createTransformer(projectSchema, 'GetProject'),
            }),
            createProject: builder.mutation({
                query: (project) => ({
                    url: `${coreApiBaseUrl}/projects/`,
                    method: 'POST',
                    body: mapToSnakeCase(project),
                }),
                invalidatesTags: ['Projects', 'Users'],
                transformResponse: createTransformer(createProjectResponseSchema, 'CreateProject'),
            }),
            deleteProject: builder.mutation({
                query: ({ projectId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Projects', 'DocumentPackages', 'Blobs', 'Findings'],
            }),
            getPackage: builder.query({
                query: ({ projectId, packageId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/`,
                transformResponse: createTransformer(documentPackageSchema, 'GetPackage'),
            }),
            getPackages: builder.query({
                query: ({ projectId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/`,
                transformResponse: createTransformer(getPackagesResponseSchema, 'GetPackages'),
                providesTags: ['DocumentPackages'],
            }),
            getPackagesByServiceId: builder.query({
                query: ({ projectId, serviceId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/?project_phase=${serviceId}`,
                transformResponse: createTransformer(getPackagesResponseSchema, 'GetPackagesByServiceId'),
                providesTags: ['DocumentPackages'],
            }),
            createPackage: builder.mutation({
                query: ({ projectId, serviceId, name, language, needClassification }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/`,
                    method: 'POST',
                    body: mapToSnakeCase({
                        name,
                        project: projectId,
                        projectPhase: serviceId,
                        language,
                        needClassification,
                    }),
                }),
                transformResponse: createTransformer(createPackageResponseSchema, 'CreatePackage'),
                invalidatesTags: ['DocumentPackages', 'Projects'],
            }),
            updatePackage: builder.mutation({
                query: ({ projectId, packageId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/`,
                    method: 'PATCH',
                    body: mapToSnakeCase(data),
                }),
                invalidatesTags: ['DocumentPackages'],
                transformResponse: createTransformer(documentPackageSchema, 'UpdateDocumentPackage'),
            }),
            deletePackage: builder.mutation({
                query: ({ projectId, packageId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['DocumentPackages', 'Blobs', 'Findings'],
            }),
            getBlob: builder.query({
                query: ({ projectId, packageId, blobId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/${blobId}/`,
                providesTags: ['Blob'],
                transformResponse: createTransformer(blobSchema, 'GetBlob'),
            }),
            getBlobs: builder.query({
                query: ({ projectId, packageId, page, searchName, searchParsedData, status, suggestion, standard, documentType, blobIds, }) => {
                    const params = createParams({
                        page,
                        searchName,
                        searchParsedData,
                        status,
                        suggestion,
                        standard,
                        documentType,
                        blobIds,
                    });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Blobs'],
                transformResponse: createTransformer(getBlobsResponseSchema, 'GetBlobs'),
            }),
            getBlobReadUrl: builder.query({
                query: ({ projectId, packageId, blobId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/${blobId}/read-url/`,
                transformResponse: createTransformer(getBlobReadUrlResponseSchema, 'GetBlobReadUrl'),
            }),
            createBlob: builder.mutation({
                query: ({ projectId, packageId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/`,
                    method: 'POST',
                    body: mapToSnakeCase(data),
                }),
                invalidatesTags: ['Blobs'],
                transformResponse: createTransformer(blobSchema, 'CreateBlob'),
            }),
            updateBlob: builder.mutation({
                query: ({ projectId, packageId, blobId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/${blobId}/`,
                    method: 'PATCH',
                    body: mapToSnakeCase(data),
                }),
                invalidatesTags: ['Blobs', 'NSCount', 'UniqueDocumentTypes'],
                transformResponse: createTransformer(blobSchema, 'UpdateBlob'),
            }),
            updateBatchBlobs: builder.mutation({
                query: ({ projectId, packageId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['Blobs', 'NSCount', 'UniqueDocumentTypes'],
                transformResponse: createTransformer(updateBatchBlobsResponseSchema, 'UpdateBlobs'),
            }),
            deleteBlob: builder.mutation({
                query: ({ projectId, packageId, blobId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/${blobId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Blobs', 'Findings'],
            }),
            getBlobFilterCount: builder.query({
                query: ({ projectId, packageId, blobIds, searchParsedData }) => {
                    const params = createParams({ blobIds, searchParsedData });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/status-count/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Blobs'],
                transformResponse: createTransformer(blobFilterCountSchema, 'BlobStatusCount'),
            }),
            getBlobsNSCount: builder.query({
                query: ({ projectId, packageId, documentType, searchName, searchParsedData }) => {
                    const params = createParams({ documentType, searchName, searchParsedData });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/standard-count/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['NSCount'],
                transformResponse: createTransformer(getBlobNSCountResponseSchema, 'GetBlobNSCount'),
            }),
            getBlobUniqueDocumentTypes: builder.query({
                query: ({ projectId, packageId, standard, searchName, searchParsedData, documentType }) => {
                    const params = createParams({ standard, searchName, searchParsedData, documentType });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/document-types/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['UniqueDocumentTypes'],
                transformResponse: createTransformer(getBlobUniqueDocumentTypesResponseSchema, 'UniqueDocumentTypes'),
            }),
            getBlobHistory: builder.query({
                query: ({ projectId, packageId, page }) => {
                    const params = createParams({ page });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/history/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                transformResponse: createTransformer(getBlobHistoryResponseSchema, 'GetBlobHistory'),
            }),
            getBlobNameList: builder.query({
                query: ({ projectId, packageId, searchParsedData, status }) => {
                    const params = createParams({ searchParsedData, status });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/blobs/name-list/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                transformResponse: createTransformer(blobNameListSchema, 'GetBlobNameList'),
            }),
            getParsedDataByGuidAndContent: builder.query({
                query: ({ projectId, packageId, guid, content }) => `${coreApiBaseUrl}/projects/${projectId}/parsed-data/?document_package=${packageId}&guid=${guid}&content=${content}`,
                transformResponse: createTransformer(parsedDataResponseSchema, 'SearchParsedDataByContent'),
            }),
            getFindings: builder.query({
                query: ({ projectId, packageId, page, pageSize, actionType, conflictType, conflictCategory, blobIds, search, language, processorName, commenters, }) => {
                    const params = createParams({
                        page,
                        pageSize,
                        actionType,
                        conflictType,
                        conflictCategory,
                        blobIds,
                        search,
                        language,
                        processorName,
                        commenters,
                    });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Findings'],
                transformResponse: createTransformer(getFindingsResponseSchema, 'GetFindings'),
            }),
            getFinding: builder.query({
                query: ({ projectId, packageId, findingId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/${findingId}/`,
                transformResponse: createTransformer(findingSchema, 'getFinding'),
            }),
            getDeletedFindings: builder.query({
                query: ({ projectId, packageId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/soft-deleted/`,
                transformResponse: createTransformer(getFindingsResponseSchema, 'getDeletedFindings'),
            }),
            createFinding: builder.mutation({
                query: ({ projectId, packageId, finding }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/`,
                    method: 'POST',
                    body: {
                        active: ActionStatus.UNADDRESSED,
                        ...finding,
                    },
                }),
                invalidatesTags: ['Findings'],
                transformResponse: createTransformer(createFindingResponseSchema, 'CreateFindingResponse'),
            }),
            updateFinding: builder.mutation({
                query: ({ projectId, packageId, findingId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/${findingId}/`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['Findings', 'DashboardData'],
                transformResponse: createTransformer(updateFindingResponseSchema, 'UpdateFinding'),
            }),
            updateBatchFindings: builder.mutation({
                query: ({ projectId, packageId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['Findings', 'DashboardData'],
                transformResponse: createTransformer(updateFindingResponseSchema, 'UpdateFinding'),
            }),
            getFindingComments: builder.query({
                query: ({ projectId, packageId, findingId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/${findingId}/comments/`,
                providesTags: ['FindingComments'],
                transformResponse: createTransformer(getFindingCommentsResponseSchema, 'GetFindingComments'),
            }),
            createFindingComment: builder.mutation({
                query: ({ projectId, packageId, findingId, text, type }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/${findingId}/comments/`,
                    method: 'POST',
                    body: mapToSnakeCase({
                        text,
                        type,
                    }),
                }),
                invalidatesTags: ['FindingComments', 'Findings', 'FindingCommenters'],
                transformResponse: createTransformer(createFindingCommentResponseSchema, 'CreateExportResponse'),
            }),
            getFindingsCommenters: builder.query({
                query: ({ projectId, packageId, conflictCategory, conflictType, actionType }) => {
                    const params = createParams({ actionType, conflictCategory, conflictType });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/commenters/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                transformResponse: createTransformer(getFindingsCommentersResponseSchema, 'GetFindingsCommenters'),
                providesTags: ['FindingCommenters'],
            }),
            getFindingsUniqueBlobs: builder.query({
                query: ({ projectId, packageId, conflictCategory, conflictType, actionType, commenters, }) => {
                    const params = createParams({ actionType, conflictCategory, conflictType, commenters });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/unique-blobs/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                transformResponse: createTransformer(getFindingsUniqueBlobsResponseSchema, 'GetFindingsUniqueBlobs'),
            }),
            getFindingsConflictTypeCounts: builder.query({
                query: ({ projectId, packageId, blobIds, commenters }) => {
                    const params = createParams({ blobIds, commenters });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/conflict-type-count/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Findings'],
                transformResponse: createTransformer(getFindingsConflictTypeCountResponseSchema, 'GetFindingsConflictTypeCount'),
            }),
            getFindingsActionCount: builder.query({
                query: ({ projectId, packageId, blobIds, conflictCategory, conflictType, commenters }) => {
                    const params = createParams({ blobIds, conflictCategory, conflictType, commenters });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/action-count/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Findings'],
                transformResponse: createTransformer(getFindingsActionCountResponseSchema, 'GetFindingsActionCount'),
            }),
            getExportByPackageId: builder.query({
                query: ({ projectId, packageId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/exports/`,
                providesTags: ['Exports'],
                transformResponse: createTransformer(getExportResponseSchema, 'GetExportByPackageId'),
            }),
            createExport: builder.mutation({
                query: ({ projectId, packageId, exports }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/exports/`,
                    method: 'POST',
                    body: {
                        ...exports,
                        document_package_id: packageId,
                    },
                }),
                invalidatesTags: ['Exports'],
                transformResponse: createTransformer(createExportResponseSchema, 'CreateExportResponse'),
            }),
            updateExport: builder.mutation({
                query: ({ projectId, packageId, data, exportId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/exports/${exportId}`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['Exports'],
                transformResponse: createTransformer(updateExportResponseSchema, 'UpdateExport'),
            }),
            createFile: builder.mutation({
                query: ({ projectId, file }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/files/`,
                    method: 'POST',
                    body: mapToSnakeCase(file),
                }),
                invalidatesTags: ['Files'],
                transformResponse: createTransformer(fileResponseSchema, 'createFile'),
            }),
            updateFile: builder.mutation({
                query: ({ projectId, fileId, file }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/files/${fileId}/`,
                    method: 'PATCH',
                    body: mapToSnakeCase(file),
                }),
                invalidatesTags: ['Files'],
                transformResponse: createTransformer(fileResponseSchema, 'updateFile'),
            }),
            getFile: builder.query({
                query: ({ projectId, fileId }) => `${coreApiBaseUrl}/projects/${projectId}/files/${fileId}/`,
                transformResponse: createTransformer(fileResponseSchema, 'getFile'),
            }),
            deleteFile: builder.mutation({
                query: ({ projectId, fileId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/files/${fileId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Files'],
            }),
            getFileUploadUrl: builder.query({
                query: ({ projectId, fileId }) => `${coreApiBaseUrl}/projects/${projectId}/files/${fileId}/upload-url/`,
                transformResponse: createTransformer(fileUploadUrlResponseSchema, 'getFileUploadUrl'),
            }),
            getFileReadUrl: builder.query({
                query: ({ projectId, fileId }) => `${coreApiBaseUrl}/projects/${projectId}/files/${fileId}/read-url/`,
                transformResponse: createTransformer(fileReadUrlResponseSchema, 'getFileReadUrl'),
            }),
            getLayoutOptimization: builder.query({
                query: ({ projectId, layoutId }) => `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/`,
                providesTags: ['LayoutOptimization'],
                transformResponse: createTransformer(layoutOptimizationSchema, 'getLayoutOptimization'),
            }),
            getLayoutOptimizationFiles: builder.query({
                query: ({ projectId, layoutId, page, pageSize, search }) => {
                    const params = createParams({ page, pageSize, search });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/files/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['LayoutOptimizationFiles'],
                transformResponse: createTransformer(getLayoutOptimizationFilesResponseSchema, 'getLayoutOptimization'),
            }),
            getLayoutOptimizations: builder.query({
                query: ({ projectId, type }) => {
                    const params = createParams({ type });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['LayoutOptimization'],
                transformResponse: createTransformer(getLayoutOptimizationsResponseSchema, 'getLayoutOptimization'),
            }),
            createLayoutOptimization: builder.mutation({
                query: ({ projectId, payload }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/`,
                    method: 'POST',
                    body: mapToSnakeCase(payload),
                }),
                invalidatesTags: ['LayoutOptimization', 'Projects'],
                transformResponse: createTransformer(createLayoutOptimizationResponseSchema, 'CreateLayoutOptimization'),
            }),
            deleteLayoutOptimization: builder.mutation({
                query: ({ projectId, layoutOptimizationId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutOptimizationId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['LayoutOptimization'],
            }),
            createLayoutOptimizationResult: builder.mutation({
                query: ({ projectId, layoutId, layoutResult }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/results/`,
                    method: 'POST',
                    body: mapToSnakeCase(layoutResult),
                }),
                invalidatesTags: ['LayoutOptimizationResults'],
                transformResponse: createTransformer(layoutOptimizationResultSchema, 'createLayoutOptimizationResult'),
            }),
            getLayoutOptimizationResults: builder.query({
                query: ({ projectId, layoutId, page, pageSize, search }) => {
                    const params = createParams({ page, pageSize, search });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/results/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['LayoutOptimizationResults'],
                transformResponse: createTransformer(getLayoutOptimizationResultsResponseSchema, 'getLayoutOptimizationResults'),
            }),
            getLayoutOptimizationResult: builder.query({
                query: ({ projectId, layoutId, resultId }) => `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/results/${resultId}/`,
                providesTags: ['LayoutOptimizationResults'],
                transformResponse: createTransformer(layoutOptimizationResultSchema, 'getLayoutOptimizationResults'),
            }),
            deleteLayoutResult: builder.mutation({
                query: ({ projectId, layoutId, resultId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/results/${resultId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['LayoutOptimizationResults'],
            }),
            deleteLayoutFile: builder.mutation({
                query: ({ projectId, layoutId, fileId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/files/${fileId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['LayoutOptimizationFiles'],
            }),
            addLayoutFiles: builder.mutation({
                query: ({ projectId, layoutId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/layout-optimizations/${layoutId}/`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['LayoutOptimizationFiles'],
            }),
            // Structural engineering reducers:
            getStructuralEngineering: builder.query({
                query: ({ projectId, structuralId }) => `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/`,
                providesTags: ['StructuralEngineering'],
                transformResponse: createTransformer(structuralEngineeringSchema, 'getStructuralEngineering'),
            }),
            getStructuralEngineeringFiles: builder.query({
                query: ({ projectId, structuralId, page, pageSize, search }) => {
                    const params = createParams({ page, pageSize, search });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/files/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['StructuralEngineeringFiles'],
                transformResponse: createTransformer(GetStructuralEngineeringFilesResponseSchema, 'getStructuralEngineering'),
            }),
            getStructuralEngineerings: builder.query({
                query: ({ projectId, type }) => {
                    const params = createParams({ type });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/structural/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['StructuralEngineering'],
                transformResponse: createTransformer(getStructuralEngineeringResponseSchema, 'getStructuralEngineering'),
            }),
            createStructuralEngineering: builder.mutation({
                query: ({ projectId, payload }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/structural/`,
                    method: 'POST',
                    body: mapToSnakeCase(payload),
                }),
                invalidatesTags: ['StructuralEngineering', 'Projects'],
                transformResponse: createTransformer(createStructuralEngineeringResponseSchema, 'CreateStructuralEngineering'),
            }),
            deleteStructuralEngineering: builder.mutation({
                query: ({ projectId, structuralEngineeringId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralEngineeringId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['StructuralEngineering'],
            }),
            createStructuralEngineeringResult: builder.mutation({
                query: ({ projectId, structuralId, structuralResult }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/results/`,
                    method: 'POST',
                    body: mapToSnakeCase(structuralResult),
                }),
                invalidatesTags: ['StructuralEngineeringResults'],
                transformResponse: createTransformer(structuralEngineeringResultSchema, 'createStructuralEngineeringResult'),
            }),
            getStructuralEngineeringResults: builder.query({
                query: ({ projectId, structuralId, page, pageSize, search, type }) => {
                    const params = createParams({ page, pageSize, search, type });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/results/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['StructuralEngineeringResults'],
                transformResponse: createTransformer(getStructuralEngineeringResultsResponseSchema, 'getStructuralEngineeringResults'),
            }),
            getStructuralEngineeringResult: builder.query({
                query: ({ projectId, structuralId, resultId }) => `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/results/${resultId}/`,
                providesTags: ['StructuralEngineeringResults'],
                transformResponse: createTransformer(structuralEngineeringResultSchema, 'getStructuralEngineeringResults'),
            }),
            deleteStructuralEngineeringResult: builder.mutation({
                query: ({ projectId, structuralId, resultId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/results/${resultId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['StructuralEngineeringResults'],
            }),
            deleteStructuralEngineeringFile: builder.mutation({
                query: ({ projectId, structuralId, fileId }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/files/${fileId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['StructuralEngineeringFiles'],
            }),
            addStructuralEngineeringFiles: builder.mutation({
                query: ({ projectId, structuralId, data }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['StructuralEngineeringFiles'],
            }),
            getStructuralEngineeringResultsDashboard: builder.query({
                query: ({ projectId, structuralId }) => {
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/structural/${structuralId}/results/dashboard/`,
                        method: 'GET',
                    };
                },
                transformResponse: createTransformer(structuralEngineeringResultsDashboardSchema, 'getStructuralEngineeringResultsDashboard'),
            }),
            createOrganization: builder.mutation({
                query: ({ createOrg }) => ({
                    url: `${coreApiBaseUrl}/organizations/`,
                    method: 'POST',
                    body: createOrg,
                }),
                invalidatesTags: ['Organization'],
                transformResponse: createTransformer(organizationSchema, 'createOrganization'),
            }),
            getOrganizations: builder.query({
                query: () => ({
                    url: `${coreApiBaseUrl}/organizations/`,
                }),
                providesTags: ['Organization'],
                transformResponse: createTransformer(getOrganizationsResponseSchema, 'getOrganizations'),
            }),
            getOrganization: builder.query({
                query: ({ id }) => ({
                    url: `${coreApiBaseUrl}/organizations/${id}/`,
                }),
                providesTags: ['Organization'],
                transformResponse: createTransformer(organizationSchema, 'getOrganization'),
            }),
            getConflictTypes: builder.query({
                query: () => `${coreApiBaseUrl}/conflict-types/`,
                transformResponse: createTransformer(getConflictTypesResponseSchema, 'getConflictTypes'),
            }),
            getProjectPhases: builder.query({
                query: () => `${coreApiBaseUrl}/project-phases/`,
                transformResponse: createTransformer(getProjectPhasesResponseSchema, 'getProjectPhases'),
            }),
            getProjectTypes: builder.query({
                query: () => `${coreApiBaseUrl}/project-types/`,
                transformResponse: createTransformer(getProjectTypesResponseSchema, 'getProjectTypes'),
            }),
            getDashboardData: builder.query({
                query: ({ projectId, packageId }) => `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/dashboard-summary/`,
                transformResponse: createTransformer(getDashboardDataResponseSchema, 'GetDashboardData'),
                providesTags: ['DashboardData'],
            }),
            notifyPublishedPackage: builder.mutation({
                query: ({ packageId, projectId, language }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/notify/`,
                    method: 'POST',
                    body: mapToSnakeCase({ language: language }),
                }),
            }),
            exportToExcel: builder.mutation({
                query: ({ projectId, packageId, excelExport }) => {
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/export-excel/`,
                        method: 'POST',
                        body: mapToSnakeCase(excelExport),
                    };
                },
                transformResponse: createTransformer(excelExportResponseSchema, 'ExportToExcel'),
            }),
            sendFindingsMail: builder.mutation({
                query: ({ projectId, packageId, findingsMail }) => {
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/packages/${packageId}/findings/forward/`,
                        method: 'POST',
                        body: mapToSnakeCase(findingsMail),
                    };
                },
                transformResponse: createTransformer(sendFindingsMailResponseSchema, 'SendFindingsMail'),
            }),
            getProcessorJobs: builder.query({
                query: ({ projectId, packageId }) => `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/${packageId != null ? `?package=${packageId}` : ''}`,
                providesTags: ['ProcessorJobs'],
                transformResponse: createTransformer(getProcessorJobsSchema, 'GetProcessorJobs'),
            }),
            getProcessorJob: builder.query({
                query: ({ projectId, jobId }) => `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/${jobId}/`,
                providesTags: ['ProcessorJobs'],
                transformResponse: createTransformer(getProcessorJobSchema, 'GetProcessorJob'),
            }),
            getProcessorJobStages: builder.query({
                query: ({ projectId, jobId }) => `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/${jobId}/stages/`,
                transformResponse: createTransformer(getProcessorJobStagesSchema, 'GetProcessorJobStages'),
            }),
            getProcessorJobStage: builder.query({
                query: ({ projectId, jobId, stageId }) => `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/${jobId}/stages/${stageId}/`,
                transformResponse: createTransformer(getProcessorJobStageSchema, 'GetProcessorJobStage'),
            }),
            getProcessorJobStageTasks: builder.query({
                query: ({ projectId, jobId, stageId, page, pageSize, search, status }) => {
                    const params = createParams({
                        page,
                        pageSize,
                        search,
                        status,
                    });
                    return {
                        url: `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/${jobId}/stages/${stageId}/tasks/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                transformResponse: createTransformer(getProcessorJobStageTasksSchema, 'getProcessorJobStageTasks'),
            }),
            getProcessorJobStageTask: builder.query({
                query: ({ projectId, jobId, stageId, taskId }) => `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/${jobId}/stages/${stageId}/tasks/${taskId}/`,
                transformResponse: createTransformer(getProcessorJobStageTaskSchema, 'getProcessorJobStageTask'),
            }),
            setProcessorJobCompletion: builder.mutation({
                query: ({ projectId, jobId, status }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/${jobId}/`,
                    method: 'PATCH',
                    body: {
                        status,
                    },
                }),
                invalidatesTags: ['ProcessorJobs'],
                transformResponse: createTransformer(getProcessorJobSchema, 'SetProcessorJobCompletion'),
            }),
            getMyUser: builder.query({
                query: () => `${coreApiBaseUrl}/accounts/me/`,
                transformResponse: createTransformer(getUserResponseSchema, 'GetMyUser'),
                providesTags: ['Users'],
            }),
            startPipeline: builder.mutation({
                query: ({ projectId, packageId, pipelineName = PipelineName.DEFAULT, secondaryPackageId, notify, }) => ({
                    url: `${coreApiBaseUrl}/projects/${projectId}/pipeline-jobs/start/`,
                    method: 'POST',
                    body: mapToSnakeCase({
                        projectId,
                        packageId,
                        pipelineName,
                        secondaryPackageId,
                        notify,
                    }),
                }),
                transformResponse: createTransformer(startPipelineResponseSchema, 'startPipeline'),
                invalidatesTags: ['ProcessorJobs'],
            }),
            /* Chat API Endpoints */
            getPackagePropChatStatus: builder.query({
                query: ({ projectId, packageId }) => ({
                    url: `${chatApiBaseUrl}/projects/${projectId}/packages/${packageId}/status`,
                    method: 'GET',
                }),
                transformResponse: createTransformer(packageChatStatusResponseSchema, 'getPackagePropChatStatus'),
            }),
            queryPackagePropChat: builder.query({
                query: ({ projectId, packageId, query }) => ({
                    url: `${chatApiBaseUrl}/projects/${projectId}/packages/${packageId}/query`,
                    method: 'POST',
                    body: mapToSnakeCase(query),
                }),
                transformResponse: createTransformer(packageChatQueryResponseSchema, 'queryPackagePropChat'),
            }),
            getUser: builder.query({
                query: ({ id }) => ({
                    url: `${coreApiBaseUrl}/accounts/${id}/`,
                    method: 'GET',
                }),
                transformResponse: createTransformer(getUserResponseSchema, 'getUser'),
            }),
            getUsers: builder.query({
                query: ({ page, pageSize, search, orgIds, projectIds }) => {
                    const params = createParams({ page, pageSize, search, orgIds, projectIds });
                    return {
                        url: `${coreApiBaseUrl}/accounts/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Users'],
                transformResponse: createTransformer(getUsersResponseSchema, 'getUsers'),
            }),
            getUserCount: builder.query({
                query: () => `${coreApiBaseUrl}/accounts/count/`,
                transformResponse: createTransformer(getUserCountResponseSchema, 'GetUserCount'),
            }),
            inviteUser: builder.mutation({
                query: ({ recipient, organization, language, projects }) => ({
                    url: `${coreApiBaseUrl}/invites/`,
                    method: 'POST',
                    body: mapToSnakeCase({ recipient, projects, language, organization }),
                }),
                invalidatesTags: ['Invites'],
            }),
            acceptInvite: builder.mutation({
                query: (inviteId) => ({
                    url: `${coreApiBaseUrl}/invites/${inviteId}/accept/`,
                    method: 'POST',
                }),
                invalidatesTags: ['Invites', 'Users', 'Projects'],
            }),
            getInvite: builder.query({
                query: (inviteId) => ({
                    url: `${coreApiBaseUrl}/invites/${inviteId}/`,
                    method: 'GET',
                }),
                providesTags: ['Invites'],
                transformResponse: createTransformer(inviteSchema, 'getInvite'),
            }),
            getInvites: builder.query({
                query: ({ page, pageSize, status, search, recipient, invitedByEmail }) => {
                    const params = createParams({
                        page,
                        pageSize,
                        status,
                        search,
                        recipient,
                        invitedByEmail,
                    });
                    return {
                        url: `${coreApiBaseUrl}/invites/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Invites'],
                transformResponse: createTransformer(getInvitesResponseSchema, 'getInvites'),
            }),
            getInviteCount: builder.query({
                query: ({ invitedByEmail }) => {
                    const params = createParams({ invitedByEmail });
                    return {
                        url: `${coreApiBaseUrl}/invites/count/`,
                        method: 'GET',
                        params: mapToSnakeCase(params),
                    };
                },
                providesTags: ['Invites'],
                transformResponse: createTransformer(getInviteCountResponseSchema, 'getInviteCount'),
            }),
        }),
    });
};
