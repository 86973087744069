import { config, reduxStore, ViteMode } from '@consigli/hooks';
import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { App } from './app';
import './index.css';
import '@fontsource/inter';
import enTranslation from './locales/en/translation.json';
import jaTranslation from './locales/ja/translation.json';
import nbTranslation from './locales/nb/translation.json';
import nnTranslation from './locales/nn/translation.json';
import svTranslation from './locales/sv/translation.json';
import viTranslation from './locales/vi/translation.json';
// loads and inits i18n + gets the translations from the frontend
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
      nb: {
        translation: nbTranslation,
      },
      nn: {
        translation: nnTranslation,
      },
      sv: {
        translation: svTranslation,
      },
      vi: {
        translation: viTranslation,
      },
    },
    debug: false,
    fallbackLng: {
      'en-US': ['en'],
      'en-GB': ['en'],
      ja: ['ja'],
      'no-NB': ['nb'],
      'no-NN': ['nn'],
      sv: ['sv'],
      vi: ['vi'],
      default: ['nb'],
    },
    returnNull: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

//only initialize Sentry if the production mode is Production
if (config.MODE === ViteMode.PRODUCTION) {
  Sentry.init({
    dsn: 'https://3be8e02dc24dfecfe9ebcd49a0dd3add@o4506105106464768.ingest.sentry.io/4506115748659200',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = document.getElementById('root');
if (root == null) {
  throw new Error('Cannot render React app when root element is missing!');
}

createRoot(root).render(
  <StrictMode>
    <ReduxProvider store={reduxStore}>
      <App />
      <ToastContainer position="top-right" autoClose={2000} pauseOnHover theme="light" />
    </ReduxProvider>
  </StrictMode>,
);
