import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Route } from '@/routes';

export const useNavigationHistory = () => {
  const navigate = useNavigate();
  const defaultBackButtonClick = useCallback(() => {
    const pathParts = location.pathname.split('/');
    const lastSegment = pathParts[pathParts.length - 1];

    if (
      lastSegment === Route.DASHBOARD ||
      lastSegment === Route.INPUT ||
      lastSegment === Route.RESULTS ||
      lastSegment === Route.CREATE
    ) {
      const newPath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`;
      navigate(newPath);
    } else if (lastSegment === Route.STEEL_FRAMES || lastSegment === Route.CONCRETE_COLUMNS) {
      const newPath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}/${pathParts[4]}/${pathParts[5]}/${pathParts[6]}/${Route.RESULTS}`;
      navigate(newPath);
    } else {
      navigate(
        `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}/${pathParts[4]}/${pathParts[5]}/${pathParts[6]}/${Route.DASHBOARD}`,
      );
    }
  }, [navigate]);
  const wizardBackButtonClick = useCallback(() => {
    const pathParts = location.pathname.split('/');
    const lastSegment = pathParts[pathParts.length - 1];

    if (lastSegment === Route.CREATE) {
      const newPath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`;
      navigate(newPath);
    } else {
      navigate(-1);
    }
  }, [navigate]);

  return {
    defaultBackButtonClick,
    wizardBackButtonClick,
  };
};
